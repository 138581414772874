.recovery_container {
  display: flex;
  height: 100vh;
  background-color: var(--primary-bg-color);
  width: 100%;

  @media screen and (max-width: 769px) {
    flex-direction: column-reverse;

}

  .left {
    height: 100%;
    max-width: 400px;
    color: var(--primary-bg-color);
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @media screen and (max-width: 992px) {
      max-width: 330px;
    }

    @media screen and (max-width: 769px) {
      max-width: 100%;
      height: auto;

      .overlay {
        max-width: 100%;
        padding-inline: 20px;

        .bot {
          max-width: 100% !important;
          display: flex;
          justify-content: space-between !important;
          align-items: center;
          width: 100% !important;
          flex-direction: row !important;
        }
      }
    }

    .overlay {

      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: var(--secondary-bg-color);
      flex-direction: column;

      .bot {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
          font-size: 4vmax;
          line-height: 1;
          font-weight: 600;
          margin-bottom: 80px;
        }

        .logo {

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 12px;
          gap: 10px;

          p {
            font-size: 13px;
            font-weight: 900;
            color: var(--primary-bg-color);
          }

          img {
            margin-inline: auto;
            margin-bottom: 80px;
            display: block;
            width: 120px;

          }

          .footer {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              font-size: 12px;
              max-width: 100%;
              font-weight: 150;
              line-height: 150%;

            }
          }
        }
      }
    }
  }

  
  .right {
    padding: 20px;
    display: grid;
    align-content: center;
    place-items: center;
    flex-grow: 1;

    .main {

        display: flex;
        flex-direction: column;
        height: max-content;
        width: 100%;
        max-width: 546px;
        margin-inline: auto;


        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 2px 10px 2px rgba(201, 201, 201, 0.47);
        justify-content: center;
        align-items: center;


    .heading {
        text-align: center;
        font-size: 25px;
        font-weight: normal;
        margin-bottom: 20px;
        margin-top: 40px;
    }
     

    .middle-span {
        display: block;
        font-size: large;               
        color: #B0B3B9;
        padding: 10px 35px;
        margin-top: 1rem;
    }

    input {
                      
        width: 30%;
        padding: 8px 12px 8px 12px;
        overflow: hidden;
        text-align: center;
        border-radius: 10px;
        outline: none;
        margin-top: 2rem;
        background: none;
    }

    .submitbutton {
            padding-top: 10px;
            margin-top: 2rem;

        button {
            color: var(--primary-bg-color);
            font-size: 15px;
            padding: 12px 50px;
            border-radius: 25px;
            display: inline-block;
            border: 0;
            outline: 0;
            background-color: var(--secondary-bg-color);
            &:hover {
                background-color: var(--black-color);
            }
        }
    }
  

            .bottom {  
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 20px;
                cursor: pointer;
            
                .bottom-span {
                    font-size: medium;
                    text-align: left;
                    padding: 5px;  
                    margin-bottom: 1rem;
                    font-size: 12px;
            
                Button{
                        font-size: 11px;
                        position: relative;
                    }
            
                }
            }

        }
    }


}