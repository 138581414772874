.edit-profile {
    display: flex;
    height: 100vh;
    padding: 20px;
    background: var(--primary-bg-color);
   // scroll-behavior: smooth;

    .edit-password-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 220px;

        .navbarcontainer {
            left: 239px;
            right: 18px;
            position: fixed;
            z-index: 9;
            top: 0px;
            background: var(--primary-bg-color);
            border-radius: 0px 0px 25px 25px;
            border: none;

        }

        .edit-password-container {
            display: flex;
            flex-direction: column;
            max-width: 100%;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 100px;
            background-color: var(--lightgray-color);
            border-radius: 25px;


            form {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                padding: 20px;
                width: 100%;
                max-width: 500px;

                /* .edit-profile-top {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin: 10px;

                    h2 {
                        text-align: center;
                        color: var(--black-color);
                    }
                } */



                .form-top {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    margin-bottom: 10px;
                    width: 100%;

                    .form-top-img {
                        width: 70px;
                        height: 70px;

                        cursor: pointer;
                        border-radius: 50%;
                        object-fit: cover;

                    }
                }

                .form-bot {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    padding: 20px;
                    gap: 10px;
                    width: 100%;

                    input {
                        width: 100%;
                        padding: 8px 12px 8px 12px;
                        border-radius: 10px;
                        outline: none;
                        background: none;
                    }

                    .button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;

                        button {
                            color: var(--primary-bg-color);
                            font-size: 16px;
                            padding: 10px 20px;
                            border-radius: 25px;
                            display: inline-block;
                            border: 0;
                            outline: 0;
                            background-color: var(--secondary-bg-color);

                            &:hover {
                                background-color: var(--black-color);
                            }
                        }

                    }
                }
            }



        }
    }
}