.sidebar {
    height: 100vh;
    overflow: auto;
    background-color: var(--primary-bg-color);
    position: fixed;
    top: 0;
    left: 0;
    max-width: 210px;
    width: 100%;
    background-color: var(--secondary-bg-color);
    //border-radius: 25px;
    padding: 5px;
    //margin: 20px;
    z-index: 10;

    @media only screen and
        (min-device-width:320px) and 
        (max-device-width:480px)
        {
             height: 100%;
            max-width: 200px;
        }
    /* @media screen and (max-width: 570px) {
        display: none;
    } */
      @media screen and (max-width: 568px) {
         height: 100%;
         max-width: 200px;
    } 

    .top {
         height: 100px;
      
        display: flex;
        align-items: center;
       // justify-content: flex-start;
       // margin-left: 10px;
        margin-bottom: 50px;
        margin-top: 20px;

        .logo {
         //  width: 100%;

            img {
             width: 200px;
                //width: 100%;
                //text-align: center;
               // justify-content: center;
                //height: 100%;
              //  height: 45px;
            }
        }
    }


    .center {
        .accordion-section {
            border-bottom: 1px solid var(--primary-bg-color);
            transition: height 0.3s;
            overflow: hidden;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

            &.open {
                height: auto;
            }

            .clicked {
                background-color: var(--black-color);
            }
        }

        .accordion-title {
            display: flex;
            justify-content: space-between;
            padding: 12px;
            cursor: pointer;
            background-color: inherit;
            margin: 10px 0;
            color: var(--primary-bg-color);
            font-size: 16px;
            text-decoration: none;
            border-radius: 10px;

            &:hover {
                transform: scale(1);
                transform-origin: right bottom;
                background-color: var(--black-color);
            }

            .left {
                display: flex;
                gap: 5px;
                justify-content: center;
                align-items: center;

             /*   svg {
                    width: 14px;

                }*/
            }


            
            .arrow-icon {
              //  border: solid rgb(255, 255, 255);
               // border-width: 0 4px 4px 0;
                display: inline-block;
               // padding: 1px;
               // margin: 2px;
                //transform: rotate(-45deg);


                &.open {
                  //  transform: rotate(-135deg);
                    //-webkit-transform: rotate(-135deg);
                }
            }
        }

        .accordion-links {
            padding: 10px;
        }

        .accordion-link {
            padding: 10px;
            font-size: 16px;

            a {
                text-decoration: none;
                color: var(--primary-bg-color);

                &:hover {
                    text-decoration: underline;
                    text-decoration-thickness: 5px;
     
                    //color: var(--black-color);
                }
            }
        }

        /* ul {
            list-style: none;
            margin: 0;
            padding: 0;

            .title {
                font-size: 10px;
                font-weight: bold;
                color: var(--primary-bg-color);
                margin-top: 15px;
                margin-bottom: 5px;
            }
            a {
                text-decoration: none;
            }
            li {
                display: flex;
                align-items: center;
                padding: 5px;
                cursor: pointer;

                &:hover {
                    background-color: #ece8ff;
                }

                .icon {
                    font-size: 18px;
                    color: rgb(99 102 241);
                }

                span {
                    font-size: 13px;
                    font-weight: 600;
                    color: var(--primary-bg-color);;
                    margin-left: 10px;
                }
            }
        } */


    }

    .bottom {
        display: flex;
        align-items: center;
        margin: 10px;


        .colorOption {
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid rgb(99 102 241);
            cursor: pointer;
            margin: 5px;

            &:nth-child(1) {
                background-color: whitesmoke;
            }

            &:nth-child(2) {
                background-color: #333;
            }

        }
    }
}