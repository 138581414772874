.scorecard {
    display: flex;
    height: 100vh;
    padding: 20px;
    background: var(--primary-bg-color);

    .admin-sidebar {
        width: 210px;
        transition: transform 0.3s ease-in-out;
        transform: translateX(0);
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;

        z-index: 10;

        @media (max-width: 768px) {
            transform: translateX(-100%);
        }

        &.visible {
            transform: translateX(0);
        }

        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 24px;
            color: var(--primary-bg-color);
            cursor: pointer;

            @media (min-width: 769px) {
                display: none;
            }
        }
    }

    .scorecard-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 220px;

        @media (max-width: 768px) {
            margin-left: 0px;
        }

        .navbarcontainer {

            left: 220px;
            right: 2px;
            position: fixed;
            z-index: 9;
            top: 0px;
            background: var(--primary-bg-color);
            border-radius: 0px 0px 25px 25px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            @media (max-width: 768px) {
                left: 10px;
                right: 18px;
                justify-content: flex-start;
                /* Align items to the start on smaller screens */
                padding: 0 10px;
            }

            .hamburger-btn {
                display: none;
                background: none;
                border: none;
                font-size: 24px;
                color: var(--primary-bg-color);
                cursor: pointer;

                @media (max-width: 768px) {
                    display: block;
                    position: absolute;
                    top: 35px;
                    left: 20px;
                }
            }
        }

        .scorecard-upload {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 100px;

            .scorecard-upload1 {
                display: flex;
                flex-direction: column;
                width: 100%;

            }

            .top {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 100%;

                .complie-button {
                    color: var(--primary-bg-color);
                    font-size: 13px;
                    padding: 5px 0px;
                    margin-right: 5px;
                    border-radius: 25px;
                    display: inline-block;
                    border: 0;
                    outline: 0;
                    background-color: var(--secondary-bg-color);
                    text-decoration: none;
                    width: 6rem;
                    text-align: center;

                    &:hover {
                        background-color: var(--black-color);
                    }
                }

                .cis-section,
                .refund-section {
                    display: flex;
                    width: 100%;
                    padding: 15px;
                    background-color: var(--lightgray-color);
                    border-radius: 25px;
                    gap: 20px;

                    label {
                        margin-right: 10px;
                        font-size: 13px;
                        margin-top: 5px;
                        font-weight: bold;
                    }

                    .cis-available {
                        margin-left: 20px;
                        margin-top: 3px;
                        font-size: 13px;
                        font-weight: bold;
                    }

                    .filter-datemenu {
                        display: flex;

                        label {
                            margin-left: 10px;
                        }

                        .filter-datemenu-input {
                            margin-left: 5px;
                        }

                        .filter-datemenu-button {
                            color: var(--primary-bg-color);
                            font-size: 13px;

                            margin-left: 10px;
                            border-radius: 25px;
                            display: inline-block;
                            border: 0;
                            outline: 0;
                            background-color: var(--secondary-bg-color);
                            text-decoration: none;
                            width: 6rem;
                            text-align: center;

                            &:hover {
                                background-color: var(--black-color);
                            }
                        }
                    }
                }

                .block {
                    display: flex;
                    background-color: var(--lightgray-color);
                    border-radius: 25px;
                    padding: 15px;
                    width: 100%;

                    .top-block {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 12px;

                        label {

                            font-size: 13px;

                        }
                    }

                    .bottom-block {

                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-right: 20px;
                        justify-content: center;
                        font-size: 13px;

                        .file-input {
                            display: none;
                        }

                        .file-label {

                            background-color: var(--secondary-bg-color);
                            color: #fff;
                            padding: 5px 0px;
                            cursor: pointer;
                            border-radius: 25px;
                            width: 6rem;
                            text-align: center;

                        }

                        .file-label:hover {
                            background-color: var(--black-color);
                        }
                    }

                    .bottom-button {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 10px;

                        .upload-button {
                            background-color: var(--secondary-bg-color);
                            color: #fff;
                            padding: 5px 0px;
                            border: none;
                            border-radius: 25px;
                            cursor: pointer;
                            font-size: 13px;
                            width: 6rem;
                        }

                        .upload-button:hover {
                            background-color: var(--black-color);
                        }
                    }

                }

                .edit-btn {
                    color: #fff;
                    font-size: 13px;
                    padding: 10px 0px;
                    margin-right: 5px;
                    border-radius: 25px;
                    display: inline-block;
                    border: 0;
                    outline: 0;
                    background-color: var(--secondary-bg-color);
                    text-decoration: none;
                    width: 6rem;
                    text-align: center;

                    &:hover {
                        background-color: var(--black-color);
                    }
                }

            }

            .mismatchReport {
                display: flex;
                background-color: var(--lightgray-color);
                border-radius: 25px;
                padding: 20px;

                h3 {
                    margin-right: 10px;
                    font-size: 16px;
                    margin-top: 5px;
                }
            }

            .bottom {
                display: flex;
                padding: 20px;
                margin-top: 20px;
                flex-direction: column;

                background-color: var(--lightgray-color);
                border-radius: 25px;

                .table-bottom {
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: space-between;
                    gap: 10px;
                    max-height: 400px;
                    width: 100%;
                    overflow: scroll;

                    .red-text {
                        color: red;
                    }

                    table {
                        border-collapse: collapse;
                        width: 100%;

                        th,
                        td {
                            border: 1px solid var(--primary-bg-color);
                            text-align: left;
                            padding: 7px;
                            font-size: 15px;

                        }

                        th {
                            background-color: var(--lightgray-color);
                        }

                        td {
                            border-bottom: 1px solid var(--lightgray-color);
                            padding-left: 15px;

                            input {
                                width: 100%;

                            }
                        }
                    }
                }

            }


        }
    }
}