.navbar {

    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    background-color: var(--secondary-bg-color);
    border-radius: 8px;
    border: none;
    margin-top: 20px;

    .wrapper {
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-left: 20px;

        .title-nav {
            width: 100%;
            display: flex;
            align-items: center;

            h2 {
                color: var(--primary-bg-color);
                padding-bottom: 5px;
            }

        }

        .items {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .item {
                display: flex;
                align-items: center;
                margin-right: 20px;
                position: relative;

                span {
                    color: var(--primary-bg-color);
                }

                .employee-avatar {
                    position: relative;
                    cursor: pointer;
                }

                .icon {
                    font-size: 20px;
                }

                .employee-avatar.hovered img {
                    transform: scale(1.2);
                    /* Adjust the scale factor for your desired effect */
                }

                .avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    cursor: pointer;
                }

                .options {
                    position: absolute;
                    top: 100%;
                    right: -100%;
                    background-color: var(--primary-bg-color);

                    display: flex;
                    flex-direction: column;
                    z-index: 1;
                    border-radius: 8px;
                    margin-top: 10px;
                    padding: 10px;
                    width: 150px;

                    .links-icon {
                        text-decoration: none;
                        color: var(--secondary-bg-color);
                        cursor: pointer;

                    }
                }

                button {
                    padding: 10px;
                    border: none;

                    background-color: transparent;
                    color: var(--secondary-bg-color);
                    cursor: pointer;
                    text-align: left;
                    text-decoration: none;
                    font-weight: 500;
                }

                button:hover {
                    background-color: var(--lightgray-color);
                    border-radius: 8px;
                }
            }
        }
    }
}