@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,200&display=swap');

:root {
    --primary-bg-color: #fff;
    --secondary-bg-color: rgb(69, 70, 127);
    --black-color: #3b3b3b;
    --lightgray-color: rgb(240, 240, 240);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

body {
    font-family: 'Poppins', sans-serif;
    line-height: 1;

}

a {
    text-decoration: none;
    color: inherit;
}

/*
.container {
    overflow: scroll;

}

::-webkit-scrollbar {

    background: transparent;
    scroll-behavior: smooth;

}


::-webkit-scrollbar-thumb {
    background: var(--secondary-bg-color);

}*/
/*::-webkit-scrollbar {
  display: none;
}*/

ul {
    list-style: none;
}
.recharts-default-tooltip,
.custom-recharts-tooltip {
    padding: 4px 8px !important;
    box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    background-color: var(--lightgray-color) !important;
}

.recharts-tooltip-item-list {
    font-size: 13px;
    font-family: inherit !important;
    opacity: 0.9;
    color: var(--black-color);
    text-transform: capitalize;
}

.recharts-tooltip-label {
    color: var(--black-color);
    font-size: 14px;
    font-weight: 600;
}


.recharts-default-legend {
    margin-top: 16px !important;
}

.recharts-legend-item {
    display: inline-flex !important;
    align-items: flex-start !important;
}

.recharts-legend-item-text {
    text-transform: capitalize;
    color: #a5a3ae !important;
    padding-left: 4px;
    font-size: 14px;
}