.settargets {
    display: flex;
    height: 100vh;
    padding: 20px;
    background: var(--primary-bg-color);
    scroll-behavior: smooth;

    .admin-sidebar {
        width: 210px;
        transition: transform 0.3s ease-in-out;
        transform: translateX(0);
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;

        z-index: 10;

        @media (max-width: 768px) {
            transform: translateX(-100%);
        }

        &.visible {
            transform: translateX(0);
        }

        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 24px;
            color: var(--primary-bg-color);
            cursor: pointer;

            @media (min-width: 769px) {
                display: none;
            }
        }
    }

    .targetcontainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 220px;

        @media (max-width: 768px) {
            margin-left: 0px;
        }

        .navbarcontainer {

            left: 225px;
            right: 18px;
            position: fixed;
            z-index: 9;
            top: 0px;
            background: var(--primary-bg-color);
            border-radius: 0px 0px 25px 25px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 10px;
            padding-right: 2px;

            @media (max-width: 768px) {
                left: 10px;
                right: 18px;
                justify-content: flex-start;
                /* Align items to the start on smaller screens */
                padding-left: 10px;
                padding-right: 2px;
            }

            .hamburger-btn {
                display: none;
                background: none;
                border: none;
                font-size: 24px;
                color: var(--primary-bg-color);
                cursor: pointer;

                @media (max-width: 768px) {
                    display: block;
                    position: absolute;
                    top: 35px;
                    left: 20px;
                }
            }
        }

        .bottom {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 90px;


            .bottom-div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;

                .month-selector {
                    display: flex;
                    width: 100%;
                    margin-bottom: 20px;
                    display: flex;
                    gap: 20px;
                    padding: 20px;

                    background-color: var(--lightgray-color);
                    border-radius: 25px;

                    align-items: center;
                    justify-content: space-around;


                    label {
                        margin-right: 10px;
                        font-size: 13px;
                    }

                    input {
                        border: none;
                        padding: 5px;
                        border-radius: 25px;
                    }

                    select {
                        border: none;
                        padding: 5px;
                        border-radius: 25px;
                    }
                }
            }

            .target_Table {
                display: flex;
                flex-direction: column-reverse;
                justify-content: space-between;
                gap: 10px;
                width: 100%;

                .left {
                    width: 100%;
                    background-color: var(--lightgray-color);
                    border-radius: 25px;
                    height: 150px;
                    overflow: auto;
                    padding: 10px;
                    margin-bottom: 10px;

                    table {
                        border-collapse: collapse;
                        width: 100%;
                        padding: 5px;

                        th,
                        td {
                            border: 1px solid var(--primary-bg-color);
                            text-align: center;

                            font-size: 13px;

                        }

                        th {
                            background-color: var(--lightgray-color);
                            padding: 5px;
                        }

                        td {
                            border-bottom: 1px solid var(--lightgray-color);
                            padding-left: 10px;

                            input {
                                width: 100%;

                            }
                        }
                    }
                }


                .right {
                    width: 100%;
                    background-color: var(--lightgray-color);
                    border-radius: 25px;
                    height: 150px;
                    overflow: auto;
                    padding: 10px;
                    margin-bottom: 10px;

                    form {


                        td:last-child {
                            display: flex;
                            justify-content: space-evenly;
                        }

                        table {
                            border-collapse: collapse;
                            width: 100%;



                            th,
                            td {
                                border: 1px solid var(--primary-bg-color);
                                text-align: center;

                                font-size: 13px;

                            }

                            th {
                                background-color: var(--lightgray-color);
                                padding: 5px;
                            }

                            td {
                                border-bottom: 1px solid var(--lightgray-color);
                                padding-left: 10px;

                                input {
                                    width: 100%;

                                }
                            }

                        }
                    }


                }


            }

            .target_add {
                width: 100%;
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 10px;
                background-color: var(--lightgray-color);
                border-radius: 25px;
                padding: 10px;

                h2 {
                    color: var(--black-color);
                    font-size: 13px;
                }

                form {
                    display: flex;
                    flex-direction: column;
                    color: var(--black-color);
                    width: 100%;

                    .dropDownList {
                        display: flex;
                        width: 100%;
                        gap: 20px;
                        padding: 15px;
                        font-size: 14px;
                        align-items: center;

                        .title {
                            margin-right: 5px;
                        }

                        select {
                            border: none;
                            padding: 5px;
                            border-radius: 25px;
                        }

                    }

                    input {
                        width: 30%;
                        overflow: scroll;
                        padding: 10px;
                        border: 1px solid var(--lightgray-color);
                        border-radius: 25px;
                        margin: 5px;

                    }

                    .add-btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        gap: 25px;
                        margin-top: 20px;
                        padding-bottom: 20px;

                        button {
                            color: var(--primary-bg-color);
                            font-size: 13px;
                            padding: 10px 0px;
                            margin-right: 5px;
                            border-radius: 25px;
                            display: inline-block;
                            border: 0;
                            outline: 0;
                            background-color: var(--secondary-bg-color);
                            text-decoration: none;
                            width: 6rem;
                            text-align: center;

                            &:hover {
                                background-color: var(--black-color);
                            }
                        }
                    }

                }

            }
        }
    }
}