.br-main {
    display: flex;
    height: 100vh;
    padding: 20px;
    background: var(--primary-bg-color);

    .admin-sidebar {
        width: 210px;
        transition: transform 0.3s ease-in-out;
        transform: translateX(0);
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;

        z-index: 10;

        @media (max-width: 768px) {
            transform: translateX(-100%);
        }

        &.visible {
            transform: translateX(0);
        }

        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 24px;
            color: var(--primary-bg-color);
            cursor: pointer;

            @media (min-width: 769px) {
                display: none;
            }
        }
    }

    .br-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 90px;
        margin-left: 220px;

        @media (max-width: 768px) {
            margin-left: 0px;
        }


        .navbarcontainer {

            left: 220px;
            right: 2px;
            position: fixed;
            z-index: 9;
            top: 0px;
            background: var(--primary-bg-color);
            border-radius: 0px 0px 25px 25px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            @media (max-width: 768px) {
                left: 10px;
                right: 18px;
                justify-content: flex-start;
                /* Align items to the start on smaller screens */
                padding: 0 10px;
            }

            .hamburger-btn {
                display: none;
                background: none;
                border: none;
                font-size: 24px;
                color: var(--primary-bg-color);
                cursor: pointer;

                @media (max-width: 768px) {
                    display: block;
                    position: absolute;
                    top: 35px;
                    left: 20px;
                }
            }
        }


        /* .br-block {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 90px;
            max-width: calc(100% - 220px);
            margin-left: 220px;

            @media (max-width: 768px) {
                margin-left: 0px;
            }

            
        } */

        .br-block-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;

            .filter-menu-list {
                display: flex;
                color: var(--black-color);
                border-radius: 8px;
                width: 100%;
                text-align: center;
                padding: 10px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);


                /* @media screen and (max-width: 1200px) {
                    flex-direction: column;
                } */



                .filter-menu {
                    display: flex;
                    align-items: flex-start;
                    width: 100%;

                    /*  .filter-datemenu {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        @media screen and (max-width: 660px) {
                            display: flex;
                            flex-direction: column;
                            width: 100%;

                            .filter-datemenu-select {
                                width: 100%;
                                margin: 10px 0;
                            }

                            label {
                                width: 100%;
                            }
                        }

                        label {
                            margin-right: 10px;
                            font-size: 13px;
                            font-weight: bold;
                        }

                        .MuiSelect-root {
                            width: 100%;
                            padding: 10px;
                            border-radius: 8px;
                            font-size: 12px;
                            background-color: #fff;
                            border: 1px solid #ccc;
                            transition: border-color 0.3s;

                            &:last-child {
                                margin-left: 10px;
                            }

                            &:focus {
                                border-color: #007bff;
                            }
                        }

                        .MuiIconButton-root {
                            background-color: var(--secondary-bg-color);
                            color: #fff;
                            padding: 8px;
                            transition: background-color 0.3s;

                            &:hover {
                                background-color: #0056b3;
                            }
                        }
                    } */
                }

                .button-upload {
                    display: flex;
                    width: 100%;
                    border-radius: 8px;
                    justify-content: flex-end;

                    button {
                        border-radius: 8px;
                        font-size: 14px;
                        font-weight: bold;
                        text-align: center;
                        border: none;
                        cursor: pointer;
                        transition: background-color 0.3s;

                        &:hover {
                            background-color: #108800;
                        }
                    }
                }
            }

            .accountant-table {
                border-collapse: collapse;
                width: 100%;
                font-size: 12px;
                margin-top: 10px;

                th,
                td {
                    border: 1px solid #ddd;
                    padding: 3px;
                    text-align: left;

                }

                th {
                    background-color: #2dbade;
                    width: 70%;
                }

                tr {
                    font-weight: 400;
                }

                tr:hover {
                    background-color: #ddd;

                }

                tbody tr:last-child {
                    font-size: 12px;
                    font-weight: bold;
                    font-style: italic;
                    background-color: #f2f2f2;
                }

                td:last-child {
                    display: flex;
                    justify-content: space-between;

                }

            }

            .net-profit-loss {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-top: 10px;
                color: black;
                font-size: 15px;
                border: 1px solid #ddd;
                padding: 3px;
                text-align: left;
                background-color: #f2f2f2;
                font-weight: bold;

                &.negative {
                    color: red;
                }

                &.positive {
                    color: green;
                }

                .total-value {
                    display: flex;
                    justify-content: space-between;
                    width: 30%;

                    .left-span {
                        padding-left: 5px;
                    }
                }
            }
        }

        .chart {
            background: #fff;
            padding: 20px;
            border-radius: 12px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease-in-out;
            margin-top: 30px;

            &:hover {
                box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.15);
            }
        }
    }
}