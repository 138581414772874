.main-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    z-index: 20;

    .main-modal {
        display: flex;
        flex-direction: column;
        position: relative;
        background: var(--primary-bg-color) !important;
        border-radius: 25px;
        width: 60%;
        height: 90%;
        padding: 10px;

        .main-modal-header {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            h2 {
                width: 100%;
                margin: 0;
                font-size: 13px;
            }

            .main-close-btn {
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;

                button {
                    padding: 5px 10px;
                    background: none;
                    border: none;
                    font-size: 30px;
                    cursor: pointer;
                }
            }
        }

        .main-modal-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            height: max-content;
            overflow: auto;

            .store-data,
            .rep-data {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .button {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;


                    button {

                        padding: 5px 2px;
                        border: none;
                        background-color: var(--secondary-bg-color);
                        border-radius: 25px;
                        color: white;
                        font-weight: bold;
                        cursor: pointer;
                        margin-right: 20px;
                        font-size: 14px;
                        width: 100%;
                        display: flex;
                        align-items: left;
                        padding-left: 10px;


                        &:hover {
                            background-color: var(--black-color);
                        }
                    }

                    .clicked {
                        background-color: var(--black-color);
                    }
                }
            }
        }

        .submit-btn {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;

            button {
                padding: 8px 20px;
                border: none;
                background-color: var(--secondary-bg-color);
                border-radius: 25px;
                color: white;
                font-weight: bold;
                cursor: pointer;
                width: 6rem;

                &:hover {
                    background-color: var(--black-color);
                }
            }
        }
    }

}