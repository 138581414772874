.employee {
    display: flex;
    height: 100vh;
    padding: 20px;
    background: var(--primary-bg-color);

    .admin-sidebar {
        width: 210px;
        transition: transform 0.3s ease-in-out;
        transform: translateX(0);
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;

        z-index: 10;

        @media (max-width: 768px) {
            transform: translateX(-100%);
        }

        &.visible {
            transform: translateX(0);
        }

        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 24px;
            color: var(--primary-bg-color);
            cursor: pointer;

            @media (min-width: 769px) {
                display: none;
            }
        }
    }

    .employeeContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;


        .navbarcontainer {

            left: 220px;
            right: 0px;
            position: fixed;
            z-index: 9;
            top: 0px;
            background: var(--primary-bg-color);
            border-radius: 0px 0px 25px 25px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            @media (max-width: 768px) {
                left: 10px;
                right: 18px;
                justify-content: flex-start;
                /* Align items to the start on smaller screens */
                padding-left: 10px;
                padding-right: 2px;
            }

            .hamburger-btn {
                display: none;
                background: none;
                border: none;
                font-size: 24px;
                color: var(--primary-bg-color);
                cursor: pointer;

                @media (max-width: 768px) {
                    display: block;
                    position: absolute;
                    top: 35px;
                    left: 20px;
                }
            }
        }

        .empdashboard {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 100px;
            max-width: calc(100% - 220px);
            margin-left: 220px;

            @media (max-width: 768px) {
                margin-left: 0;
                max-width: 100%;
            }
        }

    }
}