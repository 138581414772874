.final-commission {
    display: flex;

    .final-commission-container {
        width: 100%;

        .final-nav_conatiner {
            display: flex;
            width: 95%;
            justify-content: flex-end;
            align-items: flex-end;
        }

        .final-commission-dashboard {
            display: flex;
            width: 95%;
            margin-top: 60px;
            margin-left: 60px;
            justify-content: flex-end;
            align-items: flex-end;

            .final-dash-container {
                text-align: center;
                width: 100%;
                margin: 0 0 0 100px;
                padding: 10px;

                .final-dash-title {
                    font-size: 24px;
                    margin-bottom: 10px;
                }

                .dash-block {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .commission-sections {

                        padding: 5px;

                        .commission-sections-title {
                            display: flex;
                            justify-content: flex-start;
                            h3 {
                                margin-bottom: 0px;
                            }
                        }

                        .commission-sections-bot {
                            display: flex;
                            padding: 20px;

                            .dash-blocks {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                border: 1px solid #b6b0b0;
                                gap: 5px;
                            }

                        }
                    }
                }
            }
        }
    }
}