.ar-main {
    display: flex;
    height: 100vh;
    padding: 20px;
    background: var(--primary-bg-color);

    .admin-sidebar {
        width: 210px;
        transition: transform 0.3s ease-in-out;
        transform: translateX(0);
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;

        z-index: 10;

        @media (max-width: 768px) {
            transform: translateX(-100%);
        }

        &.visible {
            transform: translateX(0);
        }

        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 24px;
            color: var(--primary-bg-color);
            cursor: pointer;

            @media (min-width: 769px) {
                display: none;
            }
        }
    }

    .ar-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 220px;

        @media (max-width: 768px) {
            margin-left: 0px;
        }

        .navbarcontainer {

            left: 225px;
            right: 18px;
            position: fixed;
            z-index: 9;
            top: 0px;
            background: var(--primary-bg-color);
            border-radius: 0px 0px 25px 25px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 10px;
            padding-right: 2px;

            @media (max-width: 768px) {
                left: 10px;
                right: 18px;
                justify-content: flex-start;
                /* Align items to the start on smaller screens */
                padding-left: 10px;
                padding-right: 2px;
            }

            .hamburger-btn {
                display: none;
                background: none;
                border: none;
                font-size: 24px;
                color: var(--primary-bg-color);
                cursor: pointer;

                @media (max-width: 768px) {
                    display: block;
                    position: absolute;
                    top: 35px;
                    left: 20px;
                }
            }
        }

        /*  .ar-block {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 90px;
            max-width: calc(100% - 220px);
            margin-left: 220px; */

        .ar-block-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-top: 90px;

            .loading-overlay {
                position: fixed;
                left: 239px;
                right: 18px;
                /* width: 70%; */
                height: 100%;
                top: 200px;
                max-width: calc(100% - 220px);
                border-radius: 25px;
                background-color: rgba(0, 0, 0, 0.8); // Semi-transparent black overlay
                z-index: 9999; // Ensure it appears above other content
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .filter-menu-list {
                display: flex;
                color: var(--black-color);
                border-radius: 25px;
                width: 100%;
                text-align: center;
                gap: 40px;

                @media screen and (max-width: 1200px) {
                    flex-direction: column;
                }

                .topbuttons {
                    display: flex;
                    height: 50px;
                    align-items: center;
                    justify-content: center;
                    align-self: center;
                }



                .filter-menu {
                    display: flex;
                    align-items: flex-start;
                    padding: 10px;
                    border-radius: 8px;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                    width: 100%;

                    .filter-datemenu {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        @media screen and (max-width: 660px) {
                            display: flex;
                            flex-direction: column;
                            width: 100%;

                            .filter-datemenu-select {
                                width: 100%;
                                margin: 10px 0;
                            }

                            label {
                                width: 100%;
                            }
                        }

                        label {
                            margin-right: 10px;
                            font-size: 13px;
                            font-weight: bold;
                        }

                        .MuiSelect-root {
                            width: 100%;
                            padding: 10px;
                            border-radius: 8px;
                            font-size: 12px;
                            background-color: #fff;
                            border: 1px solid #ccc;
                            transition: border-color 0.3s;

                            &:last-child {
                                margin-left: 10px;
                            }

                            &:focus {
                                border-color: #007bff;
                            }
                        }

                        .MuiIconButton-root {
                            background-color: var(--secondary-bg-color);
                            color: #fff;
                            padding: 8px;
                            transition: background-color 0.3s;

                            &:hover {
                                background-color: #0056b3;
                            }
                        }
                    }
                }
            }
        }

        .table {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 20px;
            max-height: 75vh;
            overflow: auto;
            border-radius: 8px;
            margin-top: 30px;

            .table-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                /* background-color: var(--lightgray-color); */


                .emp {
                    /* border: 1px solid var(--secondary-bg-color); */
                    border-right: 0px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    width: 100%;


                    span {
                        font-weight: bold;
                        margin-bottom: 10px;
                    }
                }

                .emp-table {
                    display: flex;
                    width: 100%;
                    gap: 100px;

                    @media screen and (max-width: 660px) {
                        display: flex;
                        flex-direction: column;
                    }


                    .table-act,
                    .table-hup {
                        display: flex;
                        flex-direction: column;
                        width: 100%;



                        /* span {
                                font-style: bold;
                            } */

                        .professional-table {
                            display: flex;
                            width: 100%;
                            border-collapse: collapse;
                            font-size: 12px;
                            height: 73vh;
                            /*  table-layout: fixed; */

                            thead,
                            tbody {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                width: 100%;
                            }

                            th,
                            td {
                                display: block;
                                padding: 10px;
                                border: 1px solid black;
                                text-align: left;
                                width: 100%;
                                height: 100%;
                            }

                            thead {
                                background-color: var(--secondary-bg-color);
                                color: var(--lightgray-color);

                                th {
                                    text-align: center;
                                    font-weight: bold;
                                }
                            }

                            tbody {
                                td {
                                    padding: 10px;
                                    border: 1px solid var(--secondary-bg-color);
                                    text-align: center;
                                }

                                td.highlight {
                                    background-color: yellow;
                                }
                            }

                            tr {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                            }

                            /* th,
                                td {
                                    padding: 10px;
                                    border: 1px solid var(--secondary-bg-color);
                                }

                                thead {
                                    background-color: var(--secondary-bg-color);
                                    color: var(--lightgray-color);

                                    th {
                                        text-align: center;
                                        font-weight: bold;
                                    }
                                }

                                tbody {
                                    tr {
                                        

                                        td {
                                            text-align: center;
                                        }
                                    }
                                } */
                        }
                    }
                }
            }
        }

        /*  } */
    }
}