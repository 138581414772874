.cisupload {
    display: flex;
    height: 100vh;
    padding: 20px;
    background: var(--primary-bg-color);

    .admin-sidebar {
        width: 210px;
        transition: transform 0.3s ease-in-out;
        transform: translateX(0);
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;

        z-index: 10;

        @media (max-width: 768px) {
            transform: translateX(-100%);
        }

        &.visible {
            transform: translateX(0);
        }

        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 24px;
            color: var(--primary-bg-color);
            cursor: pointer;

            @media (min-width: 769px) {
                display: none;
            }
        }
    }

    .cisupload-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 220px;

        @media (max-width: 768px) {
            margin-left: 0px;
        }

        .navbarcontainer {

            left: 220px;
            right: 2px;
            position: fixed;
            z-index: 9;
            top: 0px;
            background: var(--primary-bg-color);
            border-radius: 0px 0px 25px 25px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            @media (max-width: 768px) {
                left: 10px;
                right: 18px;
                justify-content: flex-start;
                /* Align items to the start on smaller screens */
                padding: 0 10px;
            }

            .hamburger-btn {
                display: none;
                background: none;
                border: none;
                font-size: 24px;
                color: var(--primary-bg-color);
                cursor: pointer;

                @media (max-width: 768px) {
                    display: block;
                    position: absolute;
                    top: 35px;
                    left: 20px;
                }
            }
        }

        .cisFormBlock {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 30px;
        }

        .cisupload-block {
            display: flex;
            width: 100%;
            margin-top: 100px;
            flex-direction: column;

            .cis {
                width: 100%;
                display: flex;
                flex-direction: column;
                text-align: center;
                margin-top: 30px;


                .top {
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    color: var(--black-color);
                    margin-top: 20px;
                    width: 100%;
                    flex-wrap: wrap;
                    gap: 20px;

                    @media screen and (max-width: 1024px) {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 20px;
                        /* flex-direction: column; */
                    }

                    .block {
                        display: flex;
                        flex-direction: column;

                        background-color: var(--lightgray-color);
                        border-radius: 25px;
                        gap: 30px;
                        padding: 20px;

                        .top-block {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;

                            h4 {
                                font-weight: bold;
                                font-size: x-large;
                            }

                            span {
                                font-size: medium;
                            }
                        }

                        .bottom-block {

                            display: flex;
                            font-size: medium;
                            margin-left: 20px;

                        }
                    }

                }

                .bottom {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 25px;

                    .sub-btn {
                        color: var(--primary-bg-color);
                        font-size: 13px;
                        padding: 10px 0px;
                        margin-right: 5px;
                        border-radius: 25px;
                        display: inline-block;
                        border: 0;
                        outline: 0;
                        background-color: var(--secondary-bg-color);
                        text-decoration: none;
                        width: 6rem;
                        text-align: center;

                        &:hover {
                            background-color: var(--black-color);
                        }
                    }
                }



            }

            .accossoryBlock {
                width: 100%;
                display: flex;
                flex-direction: column;
                text-align: center;
                margin-top: 50px;
                align-items: center;

                .block {
                    display: flex;
                    flex-direction: column;
                    background-color: var(--lightgray-color);
                    border-radius: 25px;
                    gap: 30px;
                    padding: 20px;

                    .top-block {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;

                        h4 {
                            font-weight: bold;
                            font-size: x-large;
                        }

                        span {
                            font-size: medium;
                        }
                    }

                    .bottom-block {

                        display: flex;
                        font-size: medium;
                        margin-left: 20px;

                    }
                }

                .bottom {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 25px;

                    .sub-btn {
                        color: var(--primary-bg-color);
                        font-size: 13px;
                        padding: 10px 0px;
                        margin-right: 5px;
                        border-radius: 25px;
                        display: inline-block;
                        border: 0;
                        outline: 0;
                        background-color: var(--secondary-bg-color);
                        text-decoration: none;
                        width: 6rem;
                        text-align: center;

                        &:hover {
                            background-color: var(--black-color);
                        }
                    }
                }
            }

            .hoursBlock {
                width: 100%;
                display: flex;
                flex-direction: column;
                text-align: center;
                margin-top: 50px;
                align-items: center;

                .block {
                    display: flex;
                    flex-direction: column;
                    background-color: var(--lightgray-color);
                    border-radius: 25px;
                    gap: 30px;
                    padding: 20px;

                    .top-block {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;

                        h4 {
                            font-weight: bold;
                            font-size: x-large;
                        }

                        span {
                            font-size: medium;
                        }
                    }

                    .bottom-block {

                        display: flex;
                        font-size: medium;
                        margin-left: 20px;

                    }
                }

                .bottom {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 25px;

                    .sub-btn {
                        color: var(--primary-bg-color);
                        font-size: 13px;
                        padding: 10px 0px;
                        margin-right: 5px;
                        border-radius: 25px;
                        display: inline-block;
                        border: 0;
                        outline: 0;
                        background-color: var(--secondary-bg-color);
                        text-decoration: none;
                        width: 6rem;
                        text-align: center;

                        &:hover {
                            background-color: var(--black-color);
                        }
                    }
                }
            }
        }
    }
}