.container {
  display: flex;
  height: 100vh;
  background-color: var(--primary-bg-color);
  width: 100%;


  @media screen and (max-width: 769px) {
    flex-direction: column-reverse;

  }


  .left {
    height: 100%;
    //max-width: 400px;
    max-width: 350px;
    color: var(--primary-bg-color);
    width: 100%;
    display: flex;
    // align-items: flex-end;
    // justify-content: center;

    /* @media screen and (min-width: 1500px) {
      max-width: 505px;
      //font-size: small;

    }*/


    @media only screen and (min-device-width:320px) and (max-device-width:480px) {

      // max-width: 100%;
      .overlay .bot .logo {
        margin: 20px !important;
      }

      img {
        max-width: 20%;
      }

      .footer p {
        font-size: 8px !important;
        text-align: center !important;

      }
    }

    @media only screen and (min-device-width:601px) and (max-device-width:906px) {
      .overlay .bot .logo {
        margin-bottom: 25px !important;


      }

      .footer p {

        font-size: 10px !important;
      }

    }

    @media only screen and (min-device-width:1200px) and (max-device-width:1600px) {

      max-width: 350px;

      .overlay .bot .logo .footer p {

        font-size: 11px !important;

      }
    }


    @media screen and (max-width: 992px) {
      max-width: 350px;
    }

    @media screen and (max-width: 769px) {
      max-width: 100%;
      height: fit-content;

      .overlay {
        max-width: 100%;
        padding-inline: 20px;

        .bot {
          max-width: 100% !important;
          display: flex;
          justify-content: space-between !important;
          align-items: center;
          width: 100% !important;
          flex-direction: row !important;

          .logo {
            width: 100%;
          }

        }
      }
    }

    .overlay {

      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      //height: 100%;
      /* background: rgb(99 102 241); */
      background: var(--secondary-bg-color);
      flex-direction: column;

      .bot {
        display: flex;
        width: 100%;
        //  height: 100vh;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        /* h1 {
          font-size: 4vmax;
          line-height: 1;
          font-weight: 600;
          margin-bottom: 80px;
        }*/

        .logo {

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 10px;
          // gap: 12px;


          /* p {
            font-size: 13px;
            font-weight: 900;
            color: var(--primary-bg-color);
          }*/

          img {
            margin-inline: auto;
            margin-bottom: 5px;
            display: block;
            width: 100px;

          }

          .footer {
            display: flex;
            //justify-content: center;
            //align-items: center;
            text-align: center;
            position: fixed;
            bottom: 0;
            padding-bottom: 4px;




            p {
              font-size: 12px;
              max-width: 100%;
              font-weight: 150;
              line-height: 150%;


            }
          }
        }
      }
    }
  }

  .right {
    padding: 20px;
    display: grid;
    align-content: center;
    place-items: center;
    flex-grow: 1;

    .main {
      display: flex;
      flex-direction: column;
      height: max-content;
      width: 100%;
      max-width: 546px;
      margin-inline: auto;
      border-radius: 8px;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 2px 10px 2px rgba(201, 201, 201, 0.47);
      justify-content: center;
      align-items: center;


      .heading {
        text-align: center;
        font-size: 25px;
        font-weight: normal;
        margin-bottom: 25px;
        margin-top: 15px;
      }

      .inputs {

        width: 90%;

        label {
          display: block;
          font-size: 13px;
          margin: 8px 0;
          color: var(--black-color);
        }

        input {
          width: 100%;
          padding: 8px 12px 8px 12px;
          border-radius: 8px;
          outline: none;

          background: none;
        }

      }

      .submitbutton {
        padding-top: 10px;
        margin-bottom: 20px;

        button {
          color: var(--primary-bg-color);
          font-size: 16px;
          padding: 12px 50px;
          border-radius: 8px;
          display: inline-block;
          border: 0;
          outline: 0;
          background-color: var(--secondary-bg-color);

          &:hover {
            background-color: var(--black-color);
          }
        }
      }

      p {
        font-size: 14px;
        color: var(--primary-bg-color);

      }

      .remember-me--forget-password {
        width: 90%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 12px;


        .recover {
          font-size: 16px;
          color: var(--black-color);
          font-size: medium;
          text-align: center;
          line-height: 1.8;

          &:hover {
            color: var(--secondary-bg-color);
          }

        }
      }
    }
  }


}