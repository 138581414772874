.eh-main {
    display: flex;
    height: 100vh;
    padding: 20px;
    background: var(--primary-bg-color);

    .admin-sidebar {
        width: 210px;
        transition: transform 0.3s ease-in-out;
        transform: translateX(0);
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;

        z-index: 10;

        @media (max-width: 768px) {
            transform: translateX(-100%);
        }

        &.visible {
            transform: translateX(0);
        }

        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 24px;
            color: var(--primary-bg-color);
            cursor: pointer;

            @media (min-width: 769px) {
                display: none;
            }
        }
    }

    .eh-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 220px;

        @media (max-width: 768px) {
            margin-left: 0px;
        }

        .navbarcontainer {

            left: 225px;
            right: 18px;
            position: fixed;
            z-index: 9;
            top: 0px;
            background: var(--primary-bg-color);
            border-radius: 0px 0px 25px 25px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            @media (max-width: 768px) {
                left: 10px;
                right: 18px;
                justify-content: flex-start;
                /* Align items to the start on smaller screens */
                padding: 0 10px;
            }

            .hamburger-btn {
                display: none;
                background: none;
                border: none;
                font-size: 24px;
                color: var(--primary-bg-color);
                cursor: pointer;

                @media (max-width: 768px) {
                    display: block;
                    position: absolute;
                    top: 35px;
                    left: 20px;
                }
            }
        }

        /* .eh-block {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 90px;
            max-width: calc(100% - 220px);
            margin-left: 220px; */



        .eh-block-container {
            width: 100%;
            display: flex;
            flex-direction: column;

            @media (max-width: 768px) {
                height: auto;
            }

            .loading-overlay {
                position: fixed;
                left: 239px;
                right: 18px;
                /* width: 70%; */
                height: 100%;
                top: 200px;
                max-width: calc(100% - 220px);
                border-radius: 25px;
                background-color: rgba(0, 0, 0, 0.8); // Semi-transparent black overlay
                z-index: 9999; // Ensure it appears above other content
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .filter-menu-list {
                display: flex;
                color: var(--black-color);
                border-radius: 25px;
                width: 100%;
                text-align: center;
                gap: 40px;
                margin-top: 100px;

                @media screen and (max-width: 1200px) {
                    flex-direction: column;
                }

                .filter-menu {

                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px;
                    border-radius: 8px;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                    width: 10 0%;

                    .filter-datemenu {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        @media screen and (max-width: 660px) {
                            display: flex;
                            flex-direction: column;
                            width: 100%;

                            .filter-datemenu-select {
                                width: 100%;
                                margin: 10px 0;
                            }

                            label {
                                width: 100%;
                            }
                        }

                        label {
                            margin-right: 10px;
                            font-size: 17px;
                            margin-left: 10px;
                            font-weight: bold;
                        }

                        .filter-datemenu-input {
                            width: 100%;
                            margin-right: 10px;
                            /*   padding: 8px; */
                            border: 1px solid #ccc;
                            border-radius: 4px;
                            font-size: 14px;
                            background-color: #fff;
                            transition: border-color 0.3s;

                            &:focus {
                                border-color: #007bff;
                            }
                        }

                        .MuiSelect-root {
                            width: 100%;
                            padding: 10px;
                            border-radius: 4px;
                            font-size: 14px;
                            background-color: #fff;
                            border: 1px solid #ccc;
                            transition: border-color 0.3s;

                            &:focus {
                                border-color: #007bff;
                            }
                        }

                        .MuiIconButton-root {
                            background-color: var(--secondary-bg-color);
                            color: #fff;
                            padding: 8px;
                            transition: background-color 0.3s;

                            &:hover {
                                background-color: #0056b3;
                            }
                        }
                    }

                    label {
                        margin-right: 5px;
                        font-size: 13px;
                        margin-top: 10px;
                        text-align: center;
                    }

                    .labelFilter {

                        margin-left: 10px;
                        font-weight: bold;
                        color: var(--secondary-bg-color);
                        background-color: var(--primary-bg-color);
                        padding-top: 7px;
                        text-align: center;
                        margin-top: 0px;

                    }

                    select {
                        padding: 5px;
                        border: none;
                    }
                }
            }

            .upload-button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 30px;
            }

            .datatable {
                display: flex;
                width: 100%;
                gap: 40px;
                margin-top: 40px;

                @media screen and (max-width: 1025px) {
                    flex-direction: column;

                }

                .empTable {
                    height: 100%;
                }

                .storeTable,
                .empTable {
                    display: flex;
                    width: 100%;
                    border-collapse: collapse;
                    max-height: 75vh;
                    overflow: auto;


                    table {
                        width: 100%;
                        table-layout: auto;
                        overflow: auto;
                    }

                    th,
                    td {
                        border-bottom: 1px solid var(--secondary-bg-color);
                        text-align: left;
                        padding: 10px;
                        /* border: 1px solid var(--secondary-bg-color); */
                    }

                    thead {
                        background-color: var(--secondary-bg-color);
                        color: var(--lightgray-color);

                        th {
                            text-align: center;
                            position: sticky;
                            top: 0;
                            background-color: var(--secondary-bg-color);
                            height: 60px;
                            font-size: 16px;
                            font-weight: 130;
                            inset-block-start: 0;
                            z-index: 1;
                            color: var(--primary-bg-color);

                        }
                    }

                    td:last-child {
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        border: none;
                    }

                    tr:nth-child(even) {
                        background-color: #f2f2f2;
                    }

                    tr:hover {
                        background-color: #ddd;
                    }



                }

            }

        }

        /* } */
    }
}