.list {
    display: flex;
    width: 100%;
    height: 100vh;
    padding: 20px;
    background: var(--primary-bg-color);
    overflow: hidden;

    .admin-sidebar {
        width: 210px;
        transition: transform 0.3s ease-in-out;
        transform: translateX(0);
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;

        z-index: 10;

        @media (max-width: 768px) {
            transform: translateX(-100%);
        }

        &.visible {
            transform: translateX(0);
        }

        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 24px;
            color: var(--primary-bg-color);
            cursor: pointer;

            @media (min-width: 769px) {
                display: none;
            }
        }
    }


    .listContainer {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        margin-left: 220px;

        @media (max-width: 768px) {
            margin-left: 0px;
        }

        .navbarcontainer {

            left: 225px;
            right: 18px;
            position: fixed;
            z-index: 9;
            top: 0px;
            background: var(--primary-bg-color);
            border-radius: 0px 0px 25px 25px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            @media (max-width: 768px) {
                left: 10px;
                right: 18px;
                justify-content: flex-start;
                /* Align items to the start on smaller screens */
                padding: 0 10px;
            }

            .hamburger-btn {
                display: none;
                background: none;
                border: none;
                font-size: 24px;
                color: var(--primary-bg-color);
                cursor: pointer;

                @media (max-width: 768px) {
                    display: block;
                    position: absolute;
                    top: 35px;
                    left: 20px;
                }
            }
        }



        .bottomtable {
            display: flex;
            flex-direction: column;
            overflow: auto;
            width: 100%;
            margin-bottom: 20px;
            margin-top: 90px;
            height: 100vh;

            border-radius: 25px;
            border-collapse: collapse;

            .filterContainer {
                display: flex;
                gap: 10px;
                margin-bottom: 20px;
                width: 50%;
                justify-content: center;
                align-items: center;
                align-self: center;

                .MuiTextField-root,
                .MuiSelect-root {
                    flex: 1;
                    height: 56px;
                    margin: 0;
                }
            }


            @media only screen and (max-width: 768px),
            (min-device-width: 768px) and (max-device-width: 1024px) {
                flex-direction: column;
                border-radius: 25px;

                table,
                thead,
                tbody,
                th,
                td,
                tr {
                    display: block;

                }



                thead tr {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                }

                tr {
                    border-bottom: 6px solid var(--secondary-bg-color);
                }

                /* row values Name:  Nehal  */


                td {
                    border: none;
                    border-bottom: 4px solid var(--primary-bg-color);
                    position: relative;
                    padding-left: 50%;
                    padding-top: 8px;
                    padding-bottom: 8px;

                }

                td:before {
                    position: absolute;
                    left: 6px;
                    width: 10%;
                    padding-left: 10px;
                    white-space: nowrap;
                    font-weight: bold;

                }

                /* Add title to the fields*/

                td:nth-of-type(1):before {
                    content: "Name";
                }

                td:nth-of-type(2):before {
                    content: "Username";
                }

                td:nth-of-type(3):before {
                    content: "Password";
                }

                td:nth-of-type(4):before {
                    content: "Email";
                }

                td:nth-of-type(5):before {
                    content: "Role";
                }

                td:nth-of-type(6):before {
                    content: "Phone No.";
                }

                td:nth-of-type(7):before {
                    content: "Date of Birth";
                }

                td:nth-of-type(8):before {
                    content: "Stores";
                }

                td:nth-of-type(9):before {
                    content: "DOB";
                }

                td:nth-of-type(10):before {
                    content: "Actions";
                }
            }

            .userlist-form {
                background-color: var(--lightgray-color);
                
                td:last-child {
                    display: flex;
                    justify-content: space-evenly;
                    margin-bottom: 30px;
                }

                table {
                    width: 100%;
                    font-size: 14px;
                    table-layout: auto;
                    overflow: auto;

                    tbody,
                    td {

                        border: 1px solid var(--primary-bg-color);
                    }

                    th,
                    td {
                        text-align: center;
                        font-size: 13px;
                        border-bottom: 7px solid var(--lightgray-color);
                    }

                    th {
                        position: sticky;
                        top: 0;
                        background-color: var(--secondary-bg-color);
                        height: 60px;
                        font-size: 16px;
                        font-weight: 130;
                        inset-block-start: 0;
                        z-index: 1;
                        color: var(--primary-bg-color);
                        border-bottom: 2px solid var(--black-color);
                        border-left: 2px solid var(--black-color);
                        border-right: 2px solid var(--black-color);

                    }

                    td {

                        input,
                        textarea {
                            width: auto;
                            display: inline-block;
                            border-radius: 5px;
                            justify-content: center;
                            padding: 4px;
                            margin: 2px;
                            box-sizing: border-box;
                        }


                        input:focus {
                            border: 4px solid var(--primary-bg-color);
                        }

                        .table-btn {
                            padding: 5px 5px;
                            color: var(--black-color);
                            background-color: var(--lightgray-color);
                            display: inline-block;
                            border-radius: 5px;
                            cursor: pointer;
                            font-size: 1rem;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}