.reset-container {
    width: 100%;
    height: 100vh;
    padding: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    .reset-block {
        width: 30%;
        height: 50%;
        background-color: rgb(248, 247, 247);
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        border: 1px solid #B0B3B9;

        .main {
            display: flex;
            flex-direction: column;
            align-items: center;


            .top {
                display: flex;
                flex-direction: column;
                align-items: center;

                padding: 10px;
                margin-top: 20px;


                .top-h {
                    font-size: x-large;
                    font-weight: bolder;
                    color: #B0B3B9;
                }
            }

            .bottom {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                padding: 10px;

                .bottom-div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    padding: 10px;

                    .bottom-input {
                        overflow: hidden;
                        width: 100%;
                        padding-top: 20px;
                        text-align: center;
                        border-radius: 10px;
                        border: 1px solid #B0B3B9;
                        padding: 10px;
                        font-size: large;
                    }

                    .bottom-button {
                        color: #fff;
                        font-size: 16px;
                        padding: 10px 40px;
                        border-radius: 5px;
                        display: inline-block;
                        border: 0;
                        outline: 0;
                        background-color: rgb(99 102 241);

                        &:hover {
                            background-color: rgb(67 56 202);
                        }
                    }
                }
            }
        }
    }
}