.block {
    text-decoration: none !important;
    background-color: var(--lightgray-color);
    border-radius: 8px;
    padding: 10px;

    .block-head {

        .block-title {
            h3 {
                font-size: 20px;
                font-weight: 700;
                color: var(--black-color);
            }
        }

    }

    .line-chart {
        margin-top: 30px;
        width: 100%;

        .recharts-legend-item {
            align-items: center !important;
            margin-right: 8px;
            margin-left: 8px;
        }

        .recharts-surface {
            border-radius: 2px;
        }
    }
}